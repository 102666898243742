<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Panel header="Gestão de Links">
        <Toolbar class="p-mb-3">
          <template v-slot:left>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success"
              @click="showCreateLink()"
            />
          </template>
          <template v-slot:right> </template>
        </Toolbar>
        <DataTable
          ref="dt"
          :value="links"
          class="p-datatable-sm"
          v-model:selection="selectedLink"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :rowsPerPageOptions="[10, 20, 50]"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} usuários"
        >
          <template #header>
            <div
              class="
                table-header
                p-d-flex p-flex-column p-flex-md-row p-jc-md-between
              "
            >
              <h5>Gestão de Links</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Pesquisar..."
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>
          <Column header="Nome" field="nome" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nome:</span>
              {{ slotProps.data.nome.toUpperCase() }}
            </template>
          </Column>
          <Column header="Acesso ao Link" field="link" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Acesso ao Link:</span>
              <Button
                label="Acessar"
                class="p-button-link"
                @click="showUrlLink(slotProps.data.link)"
              />
            </template>
          </Column>

          <Column header="Dt. Cadastro" field="dt_cadastro">
            <template #body="slotProps">
              <span class="p-column-title">Dt. Cadastro:</span>
              {{ $DateTime.formatDateTime24hours(slotProps.data.dt_cadastro) }}
            </template>
          </Column>
          <Column header="Dt. Atualização" field="dt_atualizacao">
            <template #body="slotProps">
              <span class="p-column-title">Status:</span>
              <span v-if="slotProps.data.dt_atualizacao">{{
                $DateTime.formatDateTime24hours(slotProps.data.dt_atualizacao)
              }}</span>
              <span v-else>NÃO ATUALIZADO.</span>
            </template>
          </Column>

          <Column>
            <template #body="slotProps">
              <span class="p-column-title">Ações:</span>
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="showUpdateLink(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA EDITAR'"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="showDeleteLink(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA EXCLUIR'"
              />
            </template>
          </Column>
        </DataTable>
      </Panel>
    </div>
  </div>
  <!--MODAL CADASTRAR-->
  <Dialog
    v-model:visible="linkDialog"
    :header="titleDialog"
    :style="{ width: '800px' }"
    :modal="true"
    @hide="hideDialogs()"
  >
    <form @submit.prevent="createOrUpdateLink(!v$.link.$invalid)">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
          <label for="nome">Nome</label>
          <InputText
            id="nome"
            v-model="v$.link.nome.$model"
            placeholder="Digite seu nome referente ao link."
            :class="{ 'p-invalid': submitted && v$.link.nome.$invalid }"
            autofocus
          />
          <small class="p-invalid" v-if="submitted && v$.link.nome.$invalid"
            >Nome é obrigatória</small
          >
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="link">Url</label>
          <InputText
            id="link"
            v-model="v$.link.link.$model"
            placeholder="Digite a url do link."
            :class="{ 'p-invalid': submitted && v$.link.link.$invalid }"
          />
          <small class="p-invalid" v-if="submitted && v$.link.link.$invalid"
            >Link é obrigatória</small
          >
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid" v-if="link.dt_cadastro">
        <div class="p-field p-col-12 p-md-6">
          <label for="dt_cadastro">Dt. Cadastro</label><br />
          <small>{{ $DateTime.formatDateTime24hours(link.dt_cadastro) }}</small>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="dt_atualizacao">Dt. Atualização</label><br />
          <small v-if="link.dt_atualizacao">{{
            $DateTime.formatDateTime24hours(link.dt_atualizacao)
          }}</small>
          <small v-else>Não Atualizado.</small>
        </div>
      </div>

      <Button
        label="Salvar"
        class="p-button-raised p-mr-2"
        icon="pi pi-check"
        type="submit"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </form>
    <template #footer> </template>
  </Dialog>
  <!--FIM MODAL CADASTRAR-->
  <!--MODAL DELETAR-->
  <Dialog
    v-model:visible="deleteLinkDialog"
    :header="titleDialog"
    :style="{ width: '450px' }"
    :modal="true"
    @hide="hideDialogs()"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="link"
        >Tem certeza de que deseja excluir o link
        <b>{{ link.nome }} ?</b>
      </span>
    </div>
    <template #footer>
      <Button
        label="Sim"
        icon="pi pi-check"
        class="p-button-raised"
        @click="deleteUser()"
      />
      <Button
        label="Não"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </template>
  </Dialog>
  <!--FIM MODAL DELETAR-->
</template>

<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import { FilterMatchMode } from "primevue/api";

import LinkService from "@/service/Links/LinkService";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      linkDialog: false,
      deleteLinkDialog: false,
      link: {
        id: null,
        nome: null,
        link: null,
        dt_cadastro: null,
        dt_atualizacao: null,
      },
      links: null,
      selectedLink: null,
      submitted: false,
    };
  },
  linkService: null,
  validations() {
    return {
      link: {
        nome: {
          required,
        },
        link: {
          required,
        },
      },
    };
  },
  created() {
    this.linkService = new LinkService();
    this.initFilters();
  },
  mounted() {
    this.findAll();
  },
  methods: {
    showCreateLink() {
      this.titleDialog = "CADASTRO DE LINKS";
      this.linkDialog = true;
    },
    showUpdateLink(link) {
      this.link = link;
      this.titleDialog = link.nome.toUpperCase();
      this.linkDialog = true;
    },
    showDeleteLink(link) {
      this.link = link;
      this.titleDialog = "EXCLUSÃO: " + link.nome.toUpperCase();
      this.deleteLinkDialog = true;
    },
    showUrlLink(url) {
      window.open(url, "_blank");
    },
    hideDialogs() {
      this.link = {
        id: null,
        nome: null,
        link: null,
        dt_cadastro: null,
        dt_atualizacao: null,
      };
      this.submitted = false;
      this.selectedLink = null;
      this.linkDialog = false;
      this.deleteLinkDialog = false;
    },
    findAll() {
      this.linkService
        .findAll()
        .then((data) => {
          this.links = data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    createOrUpdateLink(isFormValid) {
      this.submitted = true;
      if (!isFormValid) {
        return;
      }
      if (this.link.id === null) {
        this.linkService
          .create(this.link)
          .then((data) => {
            this.$msgSuccess(data);
            this.findAll();
            this.hideDialogs();
          })
          .catch((error) => {
            this.$msgErro(error);
          });
      } else {
        this.linkService
          .update(this.link.id, this.link)
          .then((data) => {
            if (data.status === 200) {
              this.$msgSuccess(data);
              this.findAll();
              this.hideDialogs();
            }
          })
          .catch((error) => {
            this.$msgErro(error);
          });
      }
    },
    deleteUser() {
      this.linkService
        .delete(this.link.id)
        .then((data) => {
          if (data.status === 204) {
            this.$msgSuccess(data);
            this.findAll();
            this.hideDialogs();
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          } else {
            this.$msgErro(error);
          }
        });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  justify-content: space-between;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-invalid {
  color: red;
}
.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-Administrador {
    background: #feedaf;
    color: #8a5340;
  }
  &.status-Operador {
    background: #c0c0c0;
    color: #000000;
  }
  &.status-Demonstracao {
    background: #b3e5fc;
    color: #23547b;
  }
  &.status-Consultor {
    background: #eccfff;
    color: #694382;
  }
  &.status-true {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-false {
    background: #ffcdd2;
    color: #c63737;
  }
}
.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none !important;
}
@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
</style>
